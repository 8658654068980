import { FaEnvelope, FaGithub, FaLinkedinIn, FaLocationArrow, FaXTwitter } from "react-icons/fa6";

import { socialMedia } from "@/data";
import MagicButton from "./ui/MagicButton";


const Footer = () => {
  return (
    <footer className="w-full pb-10 mb-[100px] md:mb-5" id="contact">
    
      <div className="flex flex-col items-center">
        <h1 className="heading lg:max-w-[45vw]">
          Ready to make <span className="text-purple">significant growth and digital impact</span> with your online presence ?
        </h1>
        <p className="text-white-200 md:mt-10 my-5 text-center">
          Reach out to me today and let&apos;s discuss how I can help you
          achieve your goals.
        </p>
        <a href="mailto:adeola_okubadejo@yahoo.com">
          <MagicButton
            title="Let's get in touch"
            icon={<FaLocationArrow />}
            position="right"
          />
        </a>


        <div className="flex space-x-3 justify-right mt-5">
          <a
            href="https://github.com/AdeolaOkubadejo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#1773EA]">
              <FaGithub />
            </span>
          </a>

          <a
            href="https://www.x.com/araamiide_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#1C9CEA]">
              <FaXTwitter />
            </span>
          </a>

          <a
            href="https://www.linkedin.com/in/adeola-o-91410094/?originalSubdomain=ng"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#0072b1]">
              <FaLinkedinIn />
            </span>
          </a>
        
        </div>


      </div>
      <div className="flex mt-16 md:flex-row flex-col justify-between items-center">
        <p className="md:text-base text-sm md:font-normal font-light">
          Copyright © 2024 Adeola Okubadejo
        </p>
        </div>

        
    </footer>
  );
};

export default Footer;